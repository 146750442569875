<template>
	<main class="page-container">
		<div class="page-header-full">
			<div class="container">
				<h2 class="page-header-full__title">소상공인 뉴스</h2>
				<p class="page-header-full__desc">온라인진출 관련<br />다양한 정보를 확인하세요</p>
			</div>
		</div>
		<div class="page-body">
			<!-- Tab list -->
			<nav class="page-nav style-orange">
				<div class="container">
					<ul class="nav-list">
						<li class="nav-item is-active">
							<router-link to="/sentcont/home">
								<span class="nav-text">소상공인 뉴스 홈</span>
							</router-link>
						</li>
						<li v-for="(item, idx) in ctgrItems" class="nav-item" :key="idx" :class="{ 'is-active': item.dcd === sentContCtgrDcd }">
							<router-link :to="`/sentcont/${item.dcd}/list`" class="nav-link">
								<span class="nav-text">{{ item.dcdVal }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</nav>
			<!-- //Tab list -->

			<div class="container">
				<section v-if="item.list.length > 0" class="home-section" v-for="(item, idx) in sorted_items" :key="idx">
					<div class="home-section-header">
						<div class="header-column">
							<h3 class="home-section-title">{{ item.sentContCtgrNm }}</h3>
						</div>
						<div class="header-column">
							<router-link :to="`/sentcont/${item.sentContCtgrDcd}/list`" class="link-more-icon">
								<span class="text">더보기</span>
							</router-link>
						</div>
					</div>
					<div class="card-list-wrapper">
						<!-- <div class="list-header">
							<template>
								<div class="header-column"></div>
								<div class="header-column">
									<ul class="sort-list">
										<li class="sort-item is-active"><button type="button" @click.prevent="selectSort('')">최신순</button></li>
										<li class="sort-item"><button type="button" @click.prevent="selectSort('I')">인기순</button></li>
									</ul>
								</div>
							</template>
						</div> -->
						<common-list :div-class="['list-body']" :is-loading="isLoading" :is-no-result="isNoResult">
							<card-list :items="item.list" :showTitle="true" />
						</common-list>
					</div>
				</section>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_SENTCONT_LIST, ACT_GET_COMMON_CODE_LIST } from '@/store/_act_consts';
import { MUT_SET_SENT_CONT_CTGR_ITEMS } from '@/store/_mut_consts';
import { getItems, getCheckItems, lengthCheck, setPaging, tryResCallback } from '@/assets/js/utils';
import CardList from '@/components/sentcont/CardList';
import NoResult from '@/components/common/NoResult';
import CommonList from '@/components/common/CommonList';
import { mapGetters } from 'vuex';
import menus from '@/assets/js/menus';

export default {
	name: 'SentContHome',
	components: {
		NoResult,
		CommonList,
		CardList,
	},
	watch: {
		ctgrItems: function (items) {
			items.forEach((element) => {
				this.getSentContList(element);
			});
		},
	},
	data: () => ({
		hasMore: false,
		totalCount: 0,
		pageSize: 12,
		pageNo: 1,
		sortCd: '',
		isNoResult: false,
		isLoading: false,
		sentContCtgrDcd: 1,
		//ctgrItems: [],
		ctgrItemsListSize: 4,
		routePush: false,
		items: [],
		tryCount: 0,
		//menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		...mapGetters('sentcont', ['ctgrItems']),
		sorted_items() {
			return this.items.sort(function (comp1, comp2) {
				var comp1UC = comp1.sentContCtgrDcd;
				var comp2UC = comp2.sentContCtgrDcd;
				if (comp1UC < comp2UC) {
					return -1;
				} else if (comp1UC > comp2UC) {
					return 1;
				}
				return 0;
			});
		},
	},
	created() {
		this.getSentContCtgrList();
	},
	methods: {
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getIssueList();
		},
		selectSort(el) {
			if (el === 'I') {
				this.sortCd = 'I';
			} else {
				this.sortCd = '';
			}
			this.getIssueList(true);
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
		getSentContCtgrList() {
			//소상공인컨텐츠 카테고리 리스트 불러오기
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {
					dcd: '1202',
					masterYn: 'N',
				})
				.then((res) => {
					const items = getCheckItems(res);
					this.$store.commit(`sentcont/${MUT_SET_SENT_CONT_CTGR_ITEMS}`, items);
				});
		},
		getSentContList(item) {
			//소상공인컨텐츠 리스트 불러오기
			this.$store
				.dispatch(`sentcont/${ACT_GET_SENTCONT_LIST}`, {
					sentContCtgrDcd: item.dcd,
					expsrYn: 'Y',
					pageNo: 1,
					pageSize: 4,
				})
				.then((res) => {
					const list = getCheckItems(res);
					if (list.length > 0) {
						//item.list = list.slice(0, this.ctgrItemsListSize);
						this.items.push({ sentContCtgrDcd: item.dcd, sentContCtgrNm: item.dcdVal, list: list });
					} else {
						//item.list = [];
						this.items.push({ sentContCtgrDcd: item.dcd, sentContCtgrNm: item.dcdVal, list: [] });
					}
				});
		},
	},
};
</script>
